import {gsap} from "gsap";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin, MorphSVGPlugin, ScrollTrigger);

jQuery(document).ready(function () {
  /*
   * ILLU JB : Home
   */
  const illu_home = document.getElementById("illu-home");
  if (illu_home) {
    // ZONE TELEPHONE
    const g_telephone = document.getElementById("telephone");
    const telephone_cercle = document.getElementById("cercle");
    const telephone_ligne_2 = document.getElementById("trait-play");
    const telephone_ligne_jauge = document.getElementById("ligne-telephone-jauge");
    const telephone_ligne_thermometre = document.getElementById("ligne-telephone-thermometre");
    const g_jauge = document.getElementById("jauge");
    const g_thermometre = document.getElementById("thermometre");
    const thermometre_ligne_1 = document.getElementById("ligne-thermometre-1");
    const thermometre_ligne_2 = document.getElementById("ligne-thermometre-2");
    const thermometre_ligne_3 = document.getElementById("ligne-thermometre-3");
    const thermometre_rectangle = document.getElementById("rectangle-start");
    const cercle_bleu = document.getElementById("cercle-cleu");
    const fleche = document.getElementById("fleche");

    const tl_1 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_1.from(fleche, {opacity: 0, y: 300, duration: 0.5});
    tl_1.fromTo(cercle_bleu, {attr: {r: 1}}, {attr: {r: 83}, duration: 0.5});
    tl_1.from(g_telephone, {opacity: 0, y: 300, duration: 1});
    tl_1.fromTo(telephone_cercle, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(telephone_ligne_2, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.from(g_jauge, {opacity: 0, y: 300, duration: 0.5});
    tl_1.from(g_thermometre, {opacity: 0, y: 300, duration: 0.5});
    tl_1.fromTo(telephone_ligne_jauge, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(telephone_ligne_thermometre, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.to(thermometre_rectangle, {attr: {y2: 550}, duration: 0.5});
    tl_1.fromTo(thermometre_ligne_1, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(thermometre_ligne_2, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(thermometre_ligne_3, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});

    const g_wifi = document.getElementById("wifi-3");
    const wifi_trait_1_3 = document.getElementById("wifi-trait-1-3");
    const wifi_trait_2_3 = document.getElementById("wifi-trait-2-3");
    const wifi_trait_3_3 = document.getElementById("wifi-trait-3-3");
    const wifi_trait_4_3 = document.getElementById("wifi-trait-4-3");

    const tl_2 = gsap.timeline({repeat: -1});
    tl_2.from(wifi_trait_1_3, {opacity: 0});
    tl_2.from(wifi_trait_2_3, {opacity: 0});
    tl_2.from(wifi_trait_3_3, {opacity: 0});
    tl_2.from(wifi_trait_4_3, {opacity: 0});
    tl_2.to(wifi_trait_1_3, {opacity: 1});
    tl_2.to(wifi_trait_2_3, {opacity: 1});
    tl_2.to(wifi_trait_3_3, {opacity: 1});
    tl_2.to(wifi_trait_4_3, {opacity: 1});

    const aiguille = document.getElementById("aiguille");

    gsap.to(aiguille, {repeat: -1, yoyo: true, duration: 2.5, rotation: 15, transformOrigin: "100% 100%"});

    const g_wave = document.getElementById("vagues-2");
    const wave_1_start_2 = document.getElementById("vague-1-start-2");
    const wave_1_end_2 = document.getElementById("vague-1-end-2");
    const wave_2_start_2 = document.getElementById("vague-2-start-2");
    const wave_2_end_2 = document.getElementById("vague-2-end-2");
    const wave_3_start_2 = document.getElementById("vague-3-start-2");
    const wave_3_end_2 = document.getElementById("vague-3-end-2");

    const tl_wave_2 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_wave_2.fromTo(wave_1_start_2, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_2.fromTo(wave_2_start_2, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_2.fromTo(wave_3_start_2, {opacity: 0}, {opacity: 1, duration: 1});
    gsap.to(wave_1_start_2, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_1_end_2});
    gsap.to(wave_2_start_2, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_2_end_2});
    gsap.to(wave_3_start_2, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_3_end_2});

    // ZONE VOITURE
    const g_voiture = document.getElementById("voiture");
    const voiture_line = document.getElementById("ligne-voiture-bloc-electrique");
    const cercle_jaune = document.getElementById("cercle-jaune");
    const fond_bloc_electrique = document.getElementById("fond-bloc-electrique");
    const eletrique_voiture = document.getElementById("eletrique-voiture");
    const feuille_2 = document.getElementById("feuille-2");
    const flocon_start = document.getElementById("flocon-start");
    const flocon_end = document.getElementById("flocon-end");
    const line_1 = document.getElementById("Ligne_170");
    const line_2 = document.getElementById("Ligne_169");
    const line_3 = document.getElementById("Ligne_168");
    const line_4 = document.getElementById("Ligne_167");
    const line_5 = document.getElementById("Ligne_166");
    const line_6 = document.getElementById("Ligne_165");

    const tl_4 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_4.from(eletrique_voiture, {opacity: 0, x: 300, duration: 1});
    tl_4.fromTo(fond_bloc_electrique, {attr: {height: 0}}, {attr: {height: 90}, duration: 0.5});
    tl_4.from(g_voiture, {opacity: 0, x: 300, duration: 1});
    tl_4.fromTo(voiture_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_4.fromTo(cercle_jaune, {attr: {r: 1}}, {attr: {r: 111}, duration: 0.5});
    tl_4.fromTo(feuille_2, {attr: {height: 0}}, {attr: {height: 136}, duration: 0.5});
    tl_4.fromTo(line_1, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_2, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_3, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_4, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_5, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_6, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.to(flocon_end, {opacity: 1});

    const g_vagues = document.getElementById("vagues");
    const wave_1_start_1 = document.getElementById("vague-1-start");
    const wave_1_end_1 = document.getElementById("vague-1-end");
    const wave_2_start_1 = document.getElementById("vague-2-start");
    const wave_2_end_1 = document.getElementById("vague-2-end");
    const wave_3_start_1 = document.getElementById("vague-3-start");
    const wave_3_end_1 = document.getElementById("vague-3-end");

    const tl_wave_1 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_wave_1.fromTo(wave_1_start_1, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_1.fromTo(wave_2_start_1, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_1.fromTo(wave_3_start_1, {opacity: 0}, {opacity: 1, duration: 1});
    gsap.to(wave_1_start_1, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_1_end_1});
    gsap.to(wave_2_start_1, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_2_end_1});
    gsap.to(wave_3_start_1, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_3_end_1});

    // ZONE MAISON
    const g_maison = document.getElementById("maison-wifi");
    const g_camera = document.getElementById("camera");
    const camera_line = document.getElementById("ligne-maison-camera");
    const prise_line = document.getElementById("ligne-maison-prise");
    const g_prise = document.getElementById("prise");
    const g_engrenage = document.getElementById("engrenage");
    const engrenage_1 = document.getElementById("engrenage-bleu");
    const engrenage_2 = document.getElementById("engrenage-blanc");
    const g_telecommande = document.getElementById("telecommande");
    const g_ampoule = document.getElementById("ampoule");
    const ampoule_line = document.getElementById("ligne-maison-ampoule");

    const tl_5 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_5.from(g_maison, {opacity: 0, x: '-300', duration: 1});
    tl_5.from(g_camera, {opacity: 0, x: '-300', duration: 0.5});
    tl_5.fromTo(camera_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_5.from(g_prise, {opacity: 0, x: '-300', duration: 1});
    tl_5.fromTo(prise_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_5.from(g_engrenage, {opacity: 0, x: '-300', duration: 1});
    tl_5.from(g_telecommande, {opacity: 0, x: '-300', duration: 1});
    tl_5.from(g_ampoule, {opacity: 0, y: '-300', duration: 1});
    tl_5.fromTo(ampoule_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});

    gsap.to(engrenage_1, {repeat: -1, rotation: 360, duration: 10, ease: 'none', transformOrigin: "50% 50%"});
    gsap.to(engrenage_2, {repeat: -1, rotation: 360, duration: 10, ease: 'none', transformOrigin: "50% 50%"});

    const g_telecommande_wifi = document.getElementById("wifi-2");
    const wifi_trait_1_2 = document.getElementById("wifi-trait-1-2");
    const wifi_trait_2_2 = document.getElementById("wifi-trait-2-2");
    const wifi_trait_3_2 = document.getElementById("wifi-trait-3-2");
    const wifi_trait_4_2 = document.getElementById("wifi-trait-4-2");

    const tl_7 = gsap.timeline({repeat: -1});
    tl_7.from(wifi_trait_1_2, {opacity: 0});
    tl_7.from(wifi_trait_2_2, {opacity: 0});
    tl_7.from(wifi_trait_3_2, {opacity: 0});
    tl_7.from(wifi_trait_4_2, {opacity: 0});
    tl_7.to(wifi_trait_1_2, {opacity: 1});
    tl_7.to(wifi_trait_2_2, {opacity: 1});
    tl_7.to(wifi_trait_3_2, {opacity: 1});
    tl_7.to(wifi_trait_4_2, {opacity: 1});

    const g_maison_wifi = document.getElementById("wifi");
    const wifi_trait_1_1 = document.getElementById("wifi-trait-1");
    const wifi_trait_2_1 = document.getElementById("wifi-trait-2");
    const wifi_trait_3_1 = document.getElementById("wifi-trait-3");
    const wifi_trait_4_1 = document.getElementById("wifi-trait-4");

    const tl_6 = gsap.timeline({repeat: -1});
    tl_6.from(wifi_trait_1_1, {opacity: 0});
    tl_6.from(wifi_trait_2_1, {opacity: 0});
    tl_6.from(wifi_trait_3_1, {opacity: 0});
    tl_6.from(wifi_trait_4_1, {opacity: 0});
    tl_6.to(wifi_trait_1_1, {opacity: 1});
    tl_6.to(wifi_trait_2_1, {opacity: 1});
    tl_6.to(wifi_trait_3_1, {opacity: 1});
    tl_6.to(wifi_trait_4_1, {opacity: 1});

    const g_maison_wave = document.getElementById("vagues-3");
    const wave_1_start_3 = document.getElementById("vague-1-start-3");
    const wave_1_end_3 = document.getElementById("vague-1-end-3");
    const wave_2_start_3 = document.getElementById("vague-2-start-3");
    const wave_2_end_3 = document.getElementById("vague-2-end-3");
    const wave_3_start_3 = document.getElementById("vague-3-start-3");
    const wave_3_end_3 = document.getElementById("vague-3-end-3");

    const tl_wave_3 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_wave_3.fromTo(wave_1_start_3, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_3.fromTo(wave_2_start_3, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_3.fromTo(wave_3_start_3, {opacity: 0}, {opacity: 1, duration: 1});
    gsap.to(wave_1_start_3, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_1_end_3});
    gsap.to(wave_2_start_3, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_2_end_3});
    gsap.to(wave_3_start_3, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_3_end_3});
  }  /*

   * ILLU JB : Contact
   */
  const illu_contact = document.getElementById("contact");
  if (illu_contact) {
    // ZONE TELEPHONE
    const g_telephone = document.getElementById("telephone");
    const telephone_cercle = document.getElementById("cercle");
    const telephone_ligne_2 = document.getElementById("trait-play");
    const telephone_ligne_jauge = document.getElementById("ligne-telephone-jauge");
    const telephone_ligne_thermometre = document.getElementById("ligne-telephone-thermometre");
    const g_jauge = document.getElementById("jauge");
    const g_thermometre = document.getElementById("thermometre");
    const thermometre_ligne_1 = document.getElementById("ligne-thermometre-1");
    const thermometre_ligne_2 = document.getElementById("ligne-thermometre-2");
    const thermometre_ligne_3 = document.getElementById("ligne-thermometre-3");
    const thermometre_rectangle = document.getElementById("rectangle-start");
    const cercle_bleu = document.getElementById("cercle-cleu");
    const fleche = document.getElementById("fleche");

    const tl_1 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_1.from(fleche, {opacity: 0, y: 300, duration: 0.5});
    tl_1.fromTo(cercle_bleu, {attr: {r: 1}}, {attr: {r: 83}, duration: 0.5});
    tl_1.from(g_telephone, {opacity: 0, y: 300, duration: 1});
    tl_1.fromTo(telephone_cercle, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(telephone_ligne_2, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.from(g_jauge, {opacity: 0, y: 300, duration: 0.5});
    tl_1.from(g_thermometre, {opacity: 0, y: 300, duration: 0.5});
    tl_1.fromTo(telephone_ligne_jauge, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(telephone_ligne_thermometre, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.to(thermometre_rectangle, {attr: {y2: 550}, duration: 0.5});
    tl_1.fromTo(thermometre_ligne_1, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(thermometre_ligne_2, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_1.fromTo(thermometre_ligne_3, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});

    const g_wifi = document.getElementById("wifi-3");
    const wifi_trait_1_3 = document.getElementById("wifi-trait-1-3");
    const wifi_trait_2_3 = document.getElementById("wifi-trait-2-3");
    const wifi_trait_3_3 = document.getElementById("wifi-trait-3-3");
    const wifi_trait_4_3 = document.getElementById("wifi-trait-4-3");

    const tl_2 = gsap.timeline({repeat: -1});
    tl_2.from(wifi_trait_1_3, {opacity: 0});
    tl_2.from(wifi_trait_2_3, {opacity: 0});
    tl_2.from(wifi_trait_3_3, {opacity: 0});
    tl_2.from(wifi_trait_4_3, {opacity: 0});
    tl_2.to(wifi_trait_1_3, {opacity: 1});
    tl_2.to(wifi_trait_2_3, {opacity: 1});
    tl_2.to(wifi_trait_3_3, {opacity: 1});
    tl_2.to(wifi_trait_4_3, {opacity: 1});

    const aiguille = document.getElementById("aiguille");

    gsap.to(aiguille, {repeat: -1, yoyo: true, duration: 2.5, rotation: 15, transformOrigin: "100% 100%"});

    const g_wave = document.getElementById("vagues-2");
    const wave_1_start_2 = document.getElementById("vague-1-start-2");
    const wave_1_end_2 = document.getElementById("vague-1-end-2");
    const wave_2_start_2 = document.getElementById("vague-2-start-2");
    const wave_2_end_2 = document.getElementById("vague-2-end-2");
    const wave_3_start_2 = document.getElementById("vague-3-start-2");
    const wave_3_end_2 = document.getElementById("vague-3-end-2");

    const tl_wave_2 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_wave_2.fromTo(wave_1_start_2, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_2.fromTo(wave_2_start_2, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_2.fromTo(wave_3_start_2, {opacity: 0}, {opacity: 1, duration: 1});
    gsap.to(wave_1_start_2, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_1_end_2});
    gsap.to(wave_2_start_2, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_2_end_2});
    gsap.to(wave_3_start_2, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_3_end_2});

    // ZONE VOITURE
    const g_voiture = document.getElementById("voiture");
    const voiture_line = document.getElementById("ligne-voiture-bloc-electrique");
    const cercle_jaune = document.getElementById("cercle-jaune");
    const fond_bloc_electrique = document.getElementById("fond-bloc-electrique");
    const eletrique_voiture = document.getElementById("eletrique-voiture");
    const feuille_2 = document.getElementById("feuille-2");
    const flocon_start = document.getElementById("flocon-start");
    const flocon_end = document.getElementById("flocon-end");
    const line_1 = document.getElementById("Ligne_170");
    const line_2 = document.getElementById("Ligne_169");
    const line_3 = document.getElementById("Ligne_168");
    const line_4 = document.getElementById("Ligne_167");
    const line_5 = document.getElementById("Ligne_166");
    const line_6 = document.getElementById("Ligne_165");

    const tl_4 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_4.from(eletrique_voiture, {opacity: 0, x: 300, duration: 1});
    tl_4.fromTo(fond_bloc_electrique, {attr: {height: 0}}, {attr: {height: 90}, duration: 0.5});
    tl_4.from(g_voiture, {opacity: 0, x: 300, duration: 1});
    tl_4.fromTo(voiture_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_4.fromTo(cercle_jaune, {attr: {r: 1}}, {attr: {r: 111}, duration: 0.5});
    tl_4.fromTo(feuille_2, {attr: {height: 0}}, {attr: {height: 136}, duration: 0.5});
    tl_4.fromTo(line_1, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_2, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_3, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_4, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_5, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.fromTo(line_6, {attr: {x2: 561.8}}, {attr: {x2: 622.8}, duration: 0.25});
    tl_4.to(flocon_end, {opacity: 1});

    const g_vagues = document.getElementById("vagues");
    const wave_1_start_1 = document.getElementById("vague-1-start");
    const wave_1_end_1 = document.getElementById("vague-1-end");
    const wave_2_start_1 = document.getElementById("vague-2-start");
    const wave_2_end_1 = document.getElementById("vague-2-end");
    const wave_3_start_1 = document.getElementById("vague-3-start");
    const wave_3_end_1 = document.getElementById("vague-3-end");

    const tl_wave_1 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_wave_1.fromTo(wave_1_start_1, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_1.fromTo(wave_2_start_1, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_1.fromTo(wave_3_start_1, {opacity: 0}, {opacity: 1, duration: 1});
    gsap.to(wave_1_start_1, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_1_end_1});
    gsap.to(wave_2_start_1, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_2_end_1});
    gsap.to(wave_3_start_1, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_3_end_1});

    // ZONE MAISON
    const g_maison = document.getElementById("maison-wifi");
    const g_camera = document.getElementById("camera");
    const camera_line = document.getElementById("ligne-maison-camera");
    const prise_line = document.getElementById("ligne-maison-prise");
    const g_prise = document.getElementById("prise");
    const g_engrenage = document.getElementById("engrenage");
    const engrenage_1 = document.getElementById("engrenage-bleu");
    const engrenage_2 = document.getElementById("engrenage-blanc");
    const g_telecommande = document.getElementById("telecommande");
    const g_ampoule = document.getElementById("ampoule");
    const ampoule_line = document.getElementById("ligne-maison-ampoule");

    const tl_5 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_5.from(g_maison, {opacity: 0, x: '-300', duration: 1});
    tl_5.from(g_camera, {opacity: 0, x: '-300', duration: 0.5});
    tl_5.fromTo(camera_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_5.from(g_prise, {opacity: 0, x: '-300', duration: 1});
    tl_5.fromTo(prise_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
    tl_5.from(g_engrenage, {opacity: 0, x: '-300', duration: 1});
    tl_5.from(g_telecommande, {opacity: 0, x: '-300', duration: 1});
    tl_5.from(g_ampoule, {opacity: 0, y: '-300', duration: 1});
    tl_5.fromTo(ampoule_line, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});

    gsap.to(engrenage_1, {repeat: -1, rotation: 360, duration: 10, ease: 'none', transformOrigin: "50% 50%"});
    gsap.to(engrenage_2, {repeat: -1, rotation: 360, duration: 10, ease: 'none', transformOrigin: "50% 50%"});

    const g_telecommande_wifi = document.getElementById("wifi-2");
    const wifi_trait_1_2 = document.getElementById("wifi-trait-1-2");
    const wifi_trait_2_2 = document.getElementById("wifi-trait-2-2");
    const wifi_trait_3_2 = document.getElementById("wifi-trait-3-2");
    const wifi_trait_4_2 = document.getElementById("wifi-trait-4-2");

    const tl_7 = gsap.timeline({repeat: -1});
    tl_7.from(wifi_trait_1_2, {opacity: 0});
    tl_7.from(wifi_trait_2_2, {opacity: 0});
    tl_7.from(wifi_trait_3_2, {opacity: 0});
    tl_7.from(wifi_trait_4_2, {opacity: 0});
    tl_7.to(wifi_trait_1_2, {opacity: 1});
    tl_7.to(wifi_trait_2_2, {opacity: 1});
    tl_7.to(wifi_trait_3_2, {opacity: 1});
    tl_7.to(wifi_trait_4_2, {opacity: 1});

    const g_maison_wifi = document.getElementById("wifi");
    const wifi_trait_1_1 = document.getElementById("wifi-trait-1");
    const wifi_trait_2_1 = document.getElementById("wifi-trait-2");
    const wifi_trait_3_1 = document.getElementById("wifi-trait-3");
    const wifi_trait_4_1 = document.getElementById("wifi-trait-4");

    const tl_6 = gsap.timeline({repeat: -1});
    tl_6.from(wifi_trait_1_1, {opacity: 0});
    tl_6.from(wifi_trait_2_1, {opacity: 0});
    tl_6.from(wifi_trait_3_1, {opacity: 0});
    tl_6.from(wifi_trait_4_1, {opacity: 0});
    tl_6.to(wifi_trait_1_1, {opacity: 1});
    tl_6.to(wifi_trait_2_1, {opacity: 1});
    tl_6.to(wifi_trait_3_1, {opacity: 1});
    tl_6.to(wifi_trait_4_1, {opacity: 1});

    const g_maison_wave = document.getElementById("vagues-3");
    const wave_1_start_3 = document.getElementById("vague-1-start-3");
    const wave_1_end_3 = document.getElementById("vague-1-end-3");
    const wave_2_start_3 = document.getElementById("vague-2-start-3");
    const wave_2_end_3 = document.getElementById("vague-2-end-3");
    const wave_3_start_3 = document.getElementById("vague-3-start-3");
    const wave_3_end_3 = document.getElementById("vague-3-end-3");

    const tl_wave_3 = gsap.timeline({repeat: 0, repeatDelay: 1});
    tl_wave_3.fromTo(wave_1_start_3, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_3.fromTo(wave_2_start_3, {opacity: 0}, {opacity: 1, duration: 1});
    tl_wave_3.fromTo(wave_3_start_3, {opacity: 0}, {opacity: 1, duration: 1});
    gsap.to(wave_1_start_3, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_1_end_3});
    gsap.to(wave_2_start_3, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_2_end_3});
    gsap.to(wave_3_start_3, {repeat: -1, yoyo: true, duration: 2.5, morphSVG: wave_3_end_3});
  }

  /*
   * ILLU JB : Electicite
   */
  const illu_electicite = document.getElementById("electricite");
  if (illu_electicite) {
    const iel_personnage = document.getElementById("electricite_personnage");
    const iel_machine = document.getElementById("electricite_machine");
    const iel_rond = document.getElementById("electricite_rond");

    const tl_electricite = gsap.timeline({
      scrollTrigger: {
        trigger: illu_electicite,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_electricite.fromTo(iel_rond, {attr: {r: 1}}, {attr: {r: 71}, duration: 1});
    tl_electricite.fromTo(iel_machine, {opacity: 0}, {opacity: 1, duration: 1});
    tl_electricite.fromTo(iel_personnage, {opacity: 0}, {opacity: 1, duration: 1});
  }

  /*
   * ILLU JB : Securite
   */
  const illu_securite = document.getElementById("securite");
  if (illu_securite) {
    const ise_camera_1 = document.getElementById("securite_camera-1");
    const ise_camera_2 = document.getElementById("securite_camera-2");
    const ise_rond = document.getElementById("securite_rond");
    const ise_telecommande = document.getElementById("securite_telecommande");
    const ise_cadena = document.getElementById("securite_cadena");

    const tl_securite = gsap.timeline({
      scrollTrigger: {
        trigger: illu_securite,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_securite.fromTo(ise_rond, {attr: {r: 1}}, {attr: {r: 71}, duration: 1});
    tl_securite.fromTo(ise_telecommande, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_securite.fromTo(ise_cadena, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_securite.fromTo(ise_camera_1, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_securite.fromTo(ise_camera_2, {opacity: 0}, {opacity: 1, duration: 0.5});
  }

  /*
   * ILLU JB : Borne de recharge
   */
  const illu_borne = document.getElementById("borne_recharge_voiture");
  if (illu_borne) {
    const ibo_rond = document.getElementById("borne_recharge_voiture_rond");
    const ibo_voiture = document.getElementById("borne_recharge_voiture-2");
    const ibo_machine = document.getElementById("borne_recharge_machine");
    const ibo_trait = document.getElementById("borne_recharge_voiture_trait");

    const tl_borne = gsap.timeline({
      scrollTrigger: {
        trigger: illu_borne,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_borne.fromTo(ibo_rond, {attr: {r: 1}}, {attr: {r: 75}, duration: 1});
    tl_borne.fromTo(ibo_voiture, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_borne.fromTo(ibo_machine, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_borne.fromTo(ibo_trait, {drawSVG: "0%"}, {duration: 0.5, drawSVG: "100%"});
  }

  /*
   * ILLU JB : Chauffage
   */
  const illu_chauffage = document.getElementById("chauffage");
  if (illu_chauffage) {
    const ich_rond = document.getElementById("chauffage_rond");
    const ich_clim = document.getElementById("chauffage_clim");
    const ich_thermometre = document.getElementById("chauffage_thermometre");
    const ich_vagues = document.getElementById("chauffage_vagues");
    const ich_radiateur = document.getElementById("chauffage_radiateur");

    const tl_chauffage = gsap.timeline({
      scrollTrigger: {
        trigger: illu_chauffage,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_chauffage.fromTo(ich_rond, {attr: {r: 1}}, {attr: {r: 75}, duration: 1});
    tl_chauffage.fromTo(ich_radiateur, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_chauffage.fromTo(ich_clim, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_chauffage.fromTo(ich_thermometre, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_chauffage.fromTo(ich_vagues, {opacity: 0}, {opacity: 1, duration: 0.5});
  }

  /*
   * ILLU JB : Eclairage
   */
  const illu_eclairage = document.getElementById("eclairage");
  if (illu_eclairage) {
    const iec_rond = document.getElementById("eclairage_rond");
    const iec_personnage = document.getElementById("eclairage_personnage");

    const tl_eclairage = gsap.timeline({
      scrollTrigger: {
        trigger: illu_eclairage,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_eclairage.fromTo(iec_rond, {attr: {r: 1}}, {attr: {r: 75}, duration: 1});
    tl_eclairage.fromTo(iec_personnage, {opacity: 0}, {opacity: 1, duration: 1});
  }

  /*
   * ILLU JB : Maison connectée
   */
  const illu_maison = document.getElementById("maison_connectee");
  if (illu_maison) {
    const ima_rond = document.getElementById("maison_connectee_rond");
    const ima_maison = document.getElementById("maison_connectee_maison");
    const ima_lumiere = document.getElementById("maison_connectee_lumiere");
    const ima_wifi = document.getElementById("maison_connectee_wifi");
    const ima_thermometre = document.getElementById("maison_connectee_thermometre");
    const ima_froid = document.getElementById("maison_connectee_froid");
    const ima_trait_1 = document.getElementById("maison_connectee_trait_maison_wifi");
    const ima_trait_2 = document.getElementById("maison_connectee_trait_maison_thermometre");
    const ima_trait_3 = document.getElementById("maison_connectee_trait_maison_froid");
    const ima_trait_4 = document.getElementById("maison_connectee_trait_maison_lumiere");

    const tl_maison = gsap.timeline({
      scrollTrigger: {
        trigger: illu_maison,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_maison.fromTo(ima_rond, {attr: {r: 1}}, {attr: {r: 75}, duration: 0.5});
    tl_maison.fromTo(ima_maison, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_maison.fromTo(ima_lumiere, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_maison.fromTo(ima_wifi, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_maison.fromTo(ima_thermometre, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_maison.fromTo(ima_froid, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_maison.fromTo(ima_trait_1, {drawSVG: "0%"}, {duration: 0.25, drawSVG: "100%"});
    tl_maison.fromTo(ima_trait_2, {drawSVG: "0%"}, {duration: 0.25, drawSVG: "100%"});
    tl_maison.fromTo(ima_trait_3, {drawSVG: "0%"}, {duration: 0.25, drawSVG: "100%"});
    tl_maison.fromTo(ima_trait_4, {drawSVG: "0%"}, {duration: 0.25, drawSVG: "100%"});
  }

  /*
   * ILLU JB : Traitement de l'air
   */
  const illu_air = document.getElementById("traitement_air");
  if (illu_air) {
    const iai_rond = document.getElementById("traitement_air_rond");
    const iai_personnage = document.getElementById("traitement_air_personnage");
    const iai_ventilation = document.getElementById("traitement_air_ventillation");
    const iai_vagues = document.getElementById("traitement_air_vagues");

    const tl_air = gsap.timeline({
      scrollTrigger: {
        trigger: illu_air,
        start: "top 80%",
        toggleActions: "play pause restart reverse",
      }
    });
    tl_air.fromTo(iai_rond, {attr: {r: 1}}, {attr: {r: 75}, duration: 1});
    tl_air.fromTo(iai_ventilation, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_air.fromTo(iai_personnage, {opacity: 0}, {opacity: 1, duration: 0.5});
    tl_air.fromTo(iai_vagues, {opacity: 0}, {opacity: 1, duration: 0.5});
  }

  /*
   * ILLU JB : Page nos services
   */
  const illu_p_services = document.getElementById("services");
  if (illu_p_services) {
    const tl_p_services = gsap.timeline();
    tl_p_services.fromTo(illu_p_services, {opacity: 0, transform: 'translate(100%, 220px)'}, {opacity: 1, transform: 'translate(0, 220px)', duration: 3});
  }

  /*
   * ILLU JB : Page actualités
   */
  const illu_p_actu = document.getElementById("actu");
  if (illu_p_actu) {
    const tl_p_actu = gsap.timeline();
    tl_p_actu.fromTo(illu_p_actu, {opacity: 0, transform: 'translate(100%, 150px)'}, {opacity: 1, transform: 'translate(0, 150px)', duration: 3});
  }

  /*
   * ILLU JB : Page nous connaitre
   */
  const illu_p_connaitre = document.getElementById("nous_connaitre");
  if (illu_p_connaitre) {
    const tl_p_connaitre = gsap.timeline();
    tl_p_connaitre.fromTo(illu_p_connaitre, {opacity: 0, transform: 'translate(100%, 100px)'}, {opacity: 1, transform: 'translate(0, 100px)', duration: 3});
  }

  /*
   * ILLU JB : Page certifications
   */
  const illu_p_certifications = document.getElementById("certifications");
  if (illu_p_certifications) {
    const tl_p_certifications = gsap.timeline();
    tl_p_certifications.fromTo(illu_p_certifications, {opacity: 0, transform: 'translate(100%, 100px)'}, {opacity: 1, transform: 'translate(0, 100px)', duration: 3});
  }

  /*
   * ILLU JB : Page nous rejoindre
   */
  const illu_p_rejoindre = document.getElementById("nous_rejoindre");
  if (illu_p_rejoindre) {
    const tl_p_rejoindre = gsap.timeline();
    tl_p_rejoindre.fromTo(illu_p_rejoindre, {opacity: 0, transform: 'translate(100%, 150px)'}, {opacity: 1, transform: 'translate(0, 150px)', duration: 3});
  }
});
